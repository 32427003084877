<template>
  <div class="successOrder textC">
    <el-image src="/img/icon/successOrder.png" lazy class="Mtp60 Mbm10"></el-image>
    <p class="textC F14 co_333 Mtp30">已成功提交订单！</p>
    <p class="textC Mtp20">
      <span class="Mrt5 co_999">点击查看订单详情</span>
      <el-link tag="a" @click="goDetail()">查看></el-link>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {},
  methods: {
    goDetail() {
      this.$router.push({
        name: 'MyOrder'
      })
    }
  }
};
</script>

<style lang="scss" scope>
.successOrder {
  height: 395px;
}
</style>