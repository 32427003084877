<template>
  <div>
    <top></top>
    <div class="contain Mbm20">
      <div class="steps">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step title="我的购物车"></el-step>
          <el-step title="填写核对订单信息"></el-step>
          <el-step title="成功提交订单"></el-step>
        </el-steps>
      </div>
      <check-order v-if="active == 2" @success="active = 3"></check-order>
      <success-order v-if="active == 3"></success-order>
    </div>
    <bottom class="bottom"></bottom>
  </div>
</template>

<script>
import top from "@/components/top.vue";
import Bottom from "@/components/bottom.vue";

import CheckOrder from './modules/CheckOrder.vue';
import SuccessOrder from './modules/SuccessOrder.vue';

export default {
  components: {
    top,
    Bottom,
    CheckOrder,
    SuccessOrder
  },
  data() {
    return {
      active: 2,
    };
  },
};
</script>

<style lang="scss" scope>
.steps {
  width: 800px;
  margin: 45px auto 30px;
}
</style>