<template>
  <div>
    <div class="main-title">填写并核对订单信息</div>
    <el-card
      class="box-card"
      v-for="(item, index) in shopList"
      :key="item.carId"
    >
      <div class="main">
        <div>
          <div class="c-title">
            收货人地址
            <el-link class="floatR" @click="addAddress()">新增地址</el-link>
          </div>
          <div class="addressInfo" v-if="item.addressInfo.addressWhole != ''">
            <div class="addressName">{{ item.addressInfo.consigneeName }}</div>
            <div class="addressWhole">
              {{ item.addressInfo.addressWhole }}
              {{ item.addressInfo.detailAddress }} {{ item.addressInfo.mobile }}
            </div>
            <span
              class="defaultAddr"
              v-if="item.addressInfo.id == defaultAddress.id"
              >默认地址</span
            >
            <el-link
              class="floatR"
              @click="showAddressModal(item.addressInfo.id, index)"
              >更换地址</el-link
            >
          </div>
          <div class="addressInfo F14 co_999" v-else>
            暂无地址信息，去
            <span class="co_primary curPointer" @click="addAddress()"
              >新增</span
            >
          </div>
        </div>
        <div>
          <div class="c-title">送货清单</div>
          <div class="shopInfo">
            <el-row :gutter="15">
              <el-col :span="3">
                <el-image :src="item.logo" style="width: 100%"></el-image>
              </el-col>
              <el-col :span="14">
                <div class="Mbm5 co_666">{{ item.goodsTitle }}</div>
                <div class="Mbm5 F14 co_666">规格：{{ item.sellName }}</div>
                <div class="Mbm5 F14 co_666">
                  交货方式：{{ $matching(item.delivery, modeOfDelivery) }}
                </div>
              </el-col>
              <el-col :span="3">
                <div class="co_f00 FBold">￥{{ getTotalPrice(item) }}</div>
              </el-col>
              <!--              <el-col :span="2">X {{ item.amount }}</el-col>-->
              <el-col :span="4">
                X
                <el-input-number
                  class="goods_num_input"
                  v-model="item.amount"
                  size="mini"
                  :min="1"
                  :max="item.inventory == '' ? 999 : Number(item.inventory)"
                ></el-input-number>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-card>
    <div class="total-info">
      <div class="textR Pbm5 F14 co_666">
        总商品金额：<span class="F30 co_f00">￥{{ totalPrice }}</span>
      </div>
      <div class="textR Pbm5 F14 co_666">
        商品数量：<span class="F16 co_f00">{{ totalNumber }}</span>
        <span class="F14">件商品</span>
      </div>
    </div>
    <div class="Mtp5 Mbm20">
      <el-button
        type="primary"
        class="submit-order floatR"
        @click="submitOrder()"
        >提交订单</el-button
      >
      <div class="clearfix"></div>
    </div>
    <el-dialog title="选择地址" :visible.sync="dialogVisible" width="600px">
      <div>
        <el-radio-group v-model="checkAddress">
          <el-radio
            :label="item.id"
            v-for="item in addressList"
            :key="item.id"
            class="Mbm15"
          >
            {{ item.addressWhole }}
            {{ item.detailAddress }}
            （{{ item.consigneeName }} 收） {{ item.mobile }}
          </el-radio>
          <br />
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="choiceAddress()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增地址窗口 -->
    <add-address ref="AddAddress" @success="defaultMethod()"></add-address>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  getMyCarDataList,
  buyerCreateOrder,
  loadUserDefaultAddress,
  findUserAddressList,
} from "@/api/user";
import { getShopDetailInfo } from "@/api/website.js";

import AddAddress from "./AddAddress";
import { modeOfDelivery } from "@/util/util";
import BigNumber from "bignumber.js";
export default {
  data() {
    return {
      modeOfDelivery,
      ids: "",
      shopList: [],
      defaultAddress: {},
      addressList: [],
      checkAddress: "",
      // 需要更换的地址index
      addressIndex: 0,
      dialogVisible: false,
    };
  },
  components: { AddAddress },
  computed: {
    ...mapGetters(["userInfo", "regionSelect"]),
    totalNumber() {
      let number = 0;
      for (let x = 0; x < this.shopList.length; x++) {
        let result = this.shopList[x];
        number = number + result.amount;
      }
      return number;
    },
    totalPrice() {
      let number = 0;
      for (let x = 0; x < this.shopList.length; x++) {
        let result = this.shopList[x];
        // number = number + result.amount * result.price;
        //修改四舍五入
        number = new BigNumber(number).plus(
          new BigNumber(result.price).multipliedBy(new BigNumber(result.amount))
        );
      }
      return number.toFixed(2);
    },
  },
  mounted() {
    this.defaultMethod();
  },
  methods: {
    defaultMethod() {
      // 获取收货列表
      this.findUserAddressList();
      let query = this.$route.query;
      // 从购物车进行下单
      if (query.type == "car") {
        this.ids = query.ids;
        Promise.all([
          this.getMyCarDataList(this.ids),
          this.loadUserDefaultAddress(this.userInfo.id),
        ]).then((res) => {
          let shopList = res[0];
          let addressInfo = res[1];
          addressInfo.addressWhole = this.areaName(addressInfo);
          for (let x = 0; x < shopList.length; x++) {
            let result = shopList[x];
            result.addressInfo = addressInfo;
          }
          this.$set(this, "shopList", shopList);
        });
      } else {
        this.ids = query.ids;
        // 从商品详情页进行下单
        Promise.all([
          this.getShopDetailInfo(this.ids),
          this.loadUserDefaultAddress(this.userInfo.id),
        ]).then((res) => {
          // 商品信息
          let shopInfo = res[0];
          // 收货地址
          let addressInfo = res[1];
          addressInfo.addressWhole = this.areaName(addressInfo);
          let tempObj = {
            addressInfo: addressInfo,
            // 商品数量
            amount: Number(query.number),
            // 商品ID
            goodsId: query.ids,
            // 商品销售参数
            goodsSellId: query.goodsSellId,
            goodsTitle: shopInfo.title,
            logo: "",
            // 商品总金额
            money: 0,
            // 单价
            price: 0,
            sellId: query.goodsSellId,
            sellName: "",
            // 用户ID
            userId: this.userInfo.id,
            // 购物车ID
            carId: "",
            delivery: "1",
          };
          for (let x = 0; x < shopInfo.goodsSellList.length; x++) {
            if (query.goodsSellId == shopInfo.goodsSellList[x].id) {
              tempObj.logo = shopInfo.goodsSellList[x].logo;
              // tempObj.money =
              //   Number(query.number) * shopInfo.goodsSellList[x].price;
              // 修改价格四舍五入
              tempObj.money = new BigNumber(shopInfo.goodsSellList[x].price)
                .multipliedBy(new BigNumber(Number(query.number)))
                .toFixed(2);
              tempObj.price = shopInfo.goodsSellList[x].price;
              tempObj.sellName = shopInfo.goodsSellList[x].typeName;
              tempObj.delivery = shopInfo.goodsSellList[x].delivery;
              tempObj.inventory = shopInfo.goodsSellList[x].inventory;
            }
          }
          this.$set(this, "shopList", [tempObj]);
        });
      }
    },
    // 获取列表数据
    getMyCarDataList(ids) {
      return new Promise((resolve) => {
        getMyCarDataList(ids).then((res) => {
          if (res.data.length == 0) {
            this.$router.push({
              path: "/",
            });
          }
          resolve(res.data);
        });
      });
    },
    // 获取默认地址
    loadUserDefaultAddress(userId) {
      return new Promise((resolve) => {
        loadUserDefaultAddress(userId).then((res) => {
          this.defaultAddress = res.data;
          resolve(res.data);
        });
      });
    },
    getShopDetailInfo(ids) {
      return new Promise((resolve) => {
        getShopDetailInfo(ids).then((res) => {
          resolve(res.data);
        });
      });
    },
    // 提交订单
    submitOrder() {
      if (this.userInfo.authentication != "Y") {
        this.$alert(
          "您的账号还未进行认证，请前往认证后再进行后续操作！",
          "提示",
          {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$router.push({
                name: "Authentication",
              });
            },
          }
        );
        return false;
      }
      let obj = {
        // 具体地址
        address: "",
        // 地址ID
        addressId: "",
        // 商品数量
        amount: 0,
        // 商品ID
        goodsId: "",
        // 商品销售参数
        goodsSellId: "",
        // 商品总金额
        money: 0,
        // 用户ID
        userId: this.userInfo.id,
        // 购物车ID
        carId: "",
      };
      let data = [];
      for (let x = 0; x < this.shopList.length; x++) {
        let result = this.shopList[x];
        let tempObj = Object.assign({}, obj);
        tempObj.address =
          result.addressInfo.addressWhole + result.addressInfo.consigneeName;
        tempObj.addressId = result.addressInfo.id;
        tempObj.amount = result.amount;
        tempObj.goodsId = result.goodsId;
        tempObj.goodsSellId = result.sellId;
        tempObj.money = result.money;
        tempObj.carId = result.carId;
        data.push(tempObj);
      }
      for (let x = 0; x < this.shopList.length; x++) {
        let result = this.shopList[x];
        if (!result.addressInfo.id) {
          this.$alert("请选择收货地址再下单", "提示", {
            type: "warning",
            confirmButtonText: "确定",
          });
          return false;
        }
      }
      buyerCreateOrder(data).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          // 获取购物车列表，获取购物车总数
          this.$store.dispatch("myCartList", this.userInfo.id);
          this.$emit("success");
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
      //判断是否有地址
      // var isAddr = function() {
      //   for (var key in data.address) {
      //     return false;
      //   }
      //   return true;
      // };
    },
    areaName(data) {
      let name = "";
      let provinceCode = data.provinceCode;
      let cityCode = data.cityCode;
      let districtCode = data.districtCode;
      let regionSelect = this.regionSelect;

      for (let x = 0; x < regionSelect.length; x++) {
        let _x = regionSelect[x];
        if (provinceCode == _x.key) {
          name = _x.title;
          if (_x.children) {
            for (let n = 0; n < _x.children.length; n++) {
              let _n = _x.children[n];
              if (cityCode == _n.key) {
                name = name + " " + _n.title;
                if (_n.children) {
                  for (let m = 0; m < _n.children.length; m++) {
                    let _m = _n.children[m];
                    if (districtCode == _m.key) {
                      name = name + " " + _m.title;
                    }
                  }
                }
              }
            }
          }
        }
      }
      return name;
    },
    // 获取收货列表
    findUserAddressList() {
      this.$set(this, "addressList", []);
      findUserAddressList(this.userInfo.id).then((res) => {
        for (let x = 0; x < res.data.length; x++) {
          let result = res.data[x];
          result.addressWhole = this.areaName(result);
          this.addressList.push(result);
        }
      });
    },
    // 打开地址选择模态框
    showAddressModal(id, index) {
      this.addressIndex = index;
      this.checkAddress = id;
      this.dialogVisible = true;
    },
    // 选择地址
    choiceAddress() {
      let id = this.checkAddress;
      for (let x = 0; x < this.addressList.length; x++) {
        let result = this.addressList[x];
        if (result.id == id) {
          this.$set(this.shopList[this.addressIndex], "addressInfo", result);
        }
      }
      this.dialogVisible = false;
    },
    // 新增地址
    addAddress() {
      this.$refs.AddAddress.addLocation();
    },
    //获取计算的两位数总金额
    getTotalPrice(item) {
      var totalPrice = new BigNumber(item.price)
        .multipliedBy(new BigNumber(item.amount))
        .toFixed(2);
      return totalPrice;
    },
  },
};
</script>

<style lang="scss" scope>
@import "@/style/variables.scss";
.main-title {
  font-weight: bold;
  font-size: 18px;
  color: $primaryColor;
  margin-bottom: 20px;
}
.box-card {
  margin-bottom: 10px;
  .c-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .shopInfo {
    padding: 25px 15px;
    background-color: #f4f4f4;
  }
}
.submit-order {
  padding: 12px 35px;
  border-radius: 0px;
}
.submit-order > span {
  font-size: 16px;
  font-weight: bold;
}
.addressInfo {
  padding: 5px;
  margin-bottom: 10px;
  .addressName {
    background-color: $primaryColor;
    display: inline-block;
    color: #fff;
    padding: 5px 50px;
    margin-right: 15px;
    font-size: 14px;
  }
  .addressWhole {
    display: inline-block;
    font-size: 14px;
  }
}
.total-info {
  padding: 25px 10px 10px;
}
.defaultAddr {
  background-color: #f4f4f4;
  padding: 7px 12px;
  margin-left: 10px;
  color: #999999;
}
.goods_num_input {
  margin-left: 10px;
  .el-input-number__increase {
    border-left: 1px solid #dcdfe6 !important;
  }
}
</style>
